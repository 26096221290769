import React, { useState, useCallback } from "react";
import axios from "axios";
import ClickSelect from "components/ClickSelect/ClickSelect";

const BulkTemplate = ({ projectId, schools }) => {
  const [url, setUrl] = useState();
  const [templateIsLoading, setTemplateIsLoading] = useState(false);
  const [templateIsReady, setTemplateIsReady] = useState(false);

  const [schoolId, setSchoolId] = useState("all");
  const selectSchools = schools.map(school => {
    return { value: school.id, label: school.label };
  });
  const selectSchoolId = e => setSchoolId(e.value);

  const [userType, setUserType] = useState("all");
  const selectUserTypes = [
    { label: "Educadores", value: "Educadores" },
    { label: "Alunos", value: "Alunos" }
  ];
  const selectuserType = e => setUserType(e.value);

  const generateFile = useCallback(() => {
    setTemplateIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/userFileDownload`, {
        headers: { "Content-Type": "application/json" },
        responseType: "arraybuffer",
        params: {
          projectId,
          schoolId,
          userType
        }
      })
      .then(response => {
        setUrl(
          window.URL.createObjectURL(
            new Blob([
              response.data,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ])
          )
        );
        setTemplateIsLoading(false);
        setTemplateIsReady(true);
      });
  }, [projectId, schoolId, userType]);

  if (templateIsReady) {
    return (
      <a
        className="button"
        href={url}
        download="lista.xlsx"
        style={{ textDecoration: "underline" }}
      >
        Baixar modelo de lista
      </a>
    );
  } else if (templateIsLoading) {
    return <p>Carregando...</p>;
  } else {
    return (
      <>
        <p>Escola</p>
        <ClickSelect options={selectSchools} onChange={selectSchoolId} />
        <p>Tipo de usuário</p>
        <ClickSelect options={selectUserTypes} onChange={selectuserType} />
        <br />
        <button onClick={generateFile}>Gerar modelo</button>
      </>
    );
  }
};

export default BulkTemplate;
