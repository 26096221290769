import { useCallback, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useLocation } from "react-router-dom";

/**
 * @description useMenuList handles the following issues on a node of menu tree:
 *  - handles getter and setter of a node of menu tree,
 *  - manages auto close when list loses focus or location changes
 *  - define ul important props
 *
 * @param {Object} [options] - the options object param
 * @param {string} [options.role="menu"] - menu role, can be menu or menubar
 * @param {string} [options.label="Menu"] - the menu label
 *
 * @returns {Object} ret - The return object
 * @returns {Object} ret.listProps - The props to attach in ul menu
 * @returns {int|null} ret.selectedItemIndex - Getter for selected item index
 * @returns {function} ret.setSelectedItemIndex - Setter for selected item index
 */
export const useMenuList = ({ role = "menu", label = "Menu" }) => {
  const ref = useRef(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const { pathname } = useLocation();
  useEffect(() => {
    /** close menu in every location change */
    if (pathname) {
      setSelectedItemIndex(null);
    }
  }, [pathname, setSelectedItemIndex]);

  useEffect(() => {
    /** close menu on every focus or click outside list */
    const nav = ref.current;
    let handleFocusChange;
    if (nav) {
      handleFocusChange = (evt) => {
        if (!nav.contains(evt.target)) {
          setSelectedItemIndex(null);
        }
      };
      document.addEventListener("focusin", handleFocusChange);
      document.addEventListener("click", handleFocusChange);
    }
    return () => {
      if (nav) {
        document.removeEventListener("focusin", handleFocusChange);
        document.removeEventListener("click", handleFocusChange);
      }
    };
  }, [ref]);

  return {
    listProps: {
      ref,
      role,
      "aria-label": label,
    },
    selectedItemIndex,
    setSelectedItemIndex,
  };
};

/**
 * @description useMenuButton handles the following issues on a node of menu tree:
 *  - define if current button is selected or not
 *  - consume popper hook in a standard way
 *  - closes on esc, toggle on click
 *  - define button important props
 *
 * @param {int} itemIndex - current button index
 * @param {int|null} selectedItem - parent menu getter for selected item
 * @param {function} setSelectedItem - parent menu getter for selected item
 * @param {string} placement - valid popper placement
 * @param {boolean} altBoundary - This describes whether to use the alt element's boundary
 * @param {int[]} offset - The basic offset accepts an array with two numbers in the form [skidding, distance]
 *
 * @returns {Object} ret - The return object
 * @returns {Object} ret.buttonProps - The props to attach in button menu
 * @returns {Object} ret.popperProps - The props to be forwarded to popper
 *                                     which is controlled by this button
 */
export const useMenuButton = (
  itemIndex,
  selectedItem,
  setSelectedItem,
  placement = "bottom-start",
  altBoundary = false,
  offset = [0, 0],
) => {
  const ref = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(ref.current, popperElement, {
    placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowElement,
          tether: false,
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["bottom"],
        },
      },
      {
        name: "preventOverflow",
        options: {
          altBoundary,
        },
      },
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
  });

  useEffect(() => {
    if (selectedItem === itemIndex && forceUpdate) {
      queueMicrotask(forceUpdate);
    }
  }, [selectedItem, itemIndex, forceUpdate]);  

  const handleClick = useCallback(() => {
    /** toggle menu opened/closed */
    setSelectedItem(itemIndex === selectedItem ? null : itemIndex);
  }, [itemIndex, selectedItem, setSelectedItem]);

  const handleKeyDown = useCallback(
    (evt) => {
      switch (evt.key) {
        /** closes menu when esc is pressed */
        case "Escape": {
          evt.preventDefault();
          setSelectedItem(null);
          break;
        }
        default: {
          break;
        }
      }
    },
    [setSelectedItem],
  );

  return {
    buttonProps: {
      type: "button",
      ref,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      "aria-haspopup": "true",
      "aria-expanded": itemIndex === selectedItem,
    },
    popperProps: {
      setPopperElement,
      setArrowElement,
      popperStyles,
      attributes,
    },
  };
};

export const useFetcher = (apiCall, translateData) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    apiCall()
      .then(({ data }) => {
        setData(translateData(data));
      })
      .catch((err) => {
        console.error(err);
        setData(null);
      })
      .finally(() => setIsLoading(false));
  }, [apiCall, translateData]);

  return {
    data,
    isLoading,
  };
};
