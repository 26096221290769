const fuzzysearch = (ssSet, compareSet, skipSameId) => {
  if (!ssSet || !compareSet) {
    // proteção contra ssSet ou compareSet indefinidos
    return [Promise.resolve([]), () => {}];
  }

  const formattedCompareSet = Object.entries(compareSet).map(([id, name]) => ({
    id,
    name,
  }));

  const fuse = new window.Fuse(formattedCompareSet, {
    keys: ["name"],
    threshold: 0.3,
    ignoreLocation: true,
  });

  const promise = new Promise((resolve) => {
    const results = Object.keys(ssSet).map((ssId) => {
      const searchName = ssSet[ssId];
      const fuzzyResults = fuse
        .search(searchName)
        .filter((res) => !skipSameId || res.item.id !== ssId)
        .map((res) => res.item.id);

      return { [ssId]: fuzzyResults };
    });

    resolve(results);
  });

  const terminate = () => {};

  return [promise, terminate];
};

export default fuzzysearch;

