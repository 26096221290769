import { createContext, useState } from "react";

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(null);
  const [mainBackground, setMainBackground] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const contextValue = {
    theme,
    setTheme,
    mainBackground,
    setMainBackground,
    collapsed,
    setCollapsed,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
