import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { tryToLoadTokens } from "./user.actions";
import Routes from "views/Routes";
import coreReducer from "./core.reducer";
import api from "./api";
import { library as fontawesome } from "@fortawesome/fontawesome-svg-core";
import { fab as brands } from "@fortawesome/free-brands-svg-icons";
import { fas as solid } from "@fortawesome/free-solid-svg-icons";
import { far as regular } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";

export * from "./utils";

fontawesome.add(brands, solid, regular);

export { api };

// Criação do `history` para navegação
export const history = createBrowserHistory();

// Configuração do Redux Store
const middlewares = [routerMiddleware(history), thunk];
const compose_with_devtools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootStore = createStore(
  coreReducer(history),
  compose_with_devtools(applyMiddleware(...middlewares))
);

// Dispara ação para carregar tokens no Redux
rootStore.dispatch(tryToLoadTokens());

// 🔥 **Transforme a exportação em um Componente Funcional**
const AppCore = () => (
  <Provider store={rootStore}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
    <ToastContainer />
  </Provider>
);

export default AppCore;
