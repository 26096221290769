import React from "react";
import Select from "react-select";
import "./ClickSelect.scss";

const ClickSelect = ({
  options,
  value,
  onChange,
  clearable,
  searchable,
  ...props
}) => {
  const selectedValue = options.find((o) => o.value === value) || null;

  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      console.warn("Nenhuma opção selecionada.");
      onChange(null);
      return;
    }
 
    onChange(selectedOption ? selectedOption.value : null);
  };

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={handleChange}
      isClearable={clearable}
      isSearchable={searchable}
      className="ClickSelect"
      classNamePrefix="ClickSelect"
      {...props}
    />
  );
};

export default ClickSelect;
