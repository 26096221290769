import ClickPagination from "components/ClickPagination/ClickPagination";
import ClickSelect from "components/ClickSelect/ClickSelect";
import { useContext, useState } from "react";
import { UserManagementContext as UMContext } from "views/UserManagement/UserManagement.context";
import { ProjectBulkUpdateContext as PBUContext } from "./ProjectBulkUpdate.context";
import "./style.scss";

/**
 * WIZARD STEP 1
 *
 * Criação de escolas não presentes no banco de dados
 * Actions disponíveis: ok, insert
 */
const WizardStep1 = ({ nextStep }) => {
  const { processedData, fuzzySSSchoolsReady, fuzzyDBSchoolsReady } =
    useContext(PBUContext);

  if (!fuzzySSSchoolsReady || !fuzzyDBSchoolsReady)
    return <h3>Carregando fuzzy</h3>;

  const processedHtml = () => {
    const obj = processedData.schools().sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    return Object.keys(obj).map((key) => {
      const elem = obj[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={elem.key} bgcolor={color}>
          <td>
            <p>{status}</p>
            <Fuzzy action={elem.action} inputKey={elem.key} dataSub="schools" />
          </td>
          <td>
            {elem.action === "insert" ? (
              <EditableInput
                text={elem.name}
                inputKey={elem.key}
                dataSub="schools"
              />
            ) : (
              elem.name
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Escolas</h3>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Nome da escola</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <SubmitStep dataSub="schools" nextStep={nextStep} />
    </>
  );
};

/**
 * WIZARD STEP 2
 *
 * Correção dos dados de níveis com problemas de digitação
 * Actions disponíveis: ok, problem
 */
const WizardStep2 = ({ nextStep }) => {
  const { processedData, databaseData } = useContext(PBUContext);

  const processedHtml = () => {
    const obj = processedData.levels();
    return Object.keys(obj).map((key) => {
      const elem = obj[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={`${elem.key}.${elem.name}`} bgcolor={color}>
          <td>
            <p>{status}</p>
          </td>
          {elem.action === "problem" ? (
            <EditableSelect
              inputKey={elem.key}
              text={elem.name}
              dataSub="levels"
              options={Object.keys(databaseData?.levels || {}).map((key) => ({
                label: databaseData.levels[key],
                value: databaseData.levels[key],
              }))}
            />
          ) : (
            <>
              <td>{elem.name}</td>
              <td>-</td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Níveis</h3>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Nível</th>
            <th>Trocar</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <SubmitStep dataSub="levels" nextStep={nextStep} />
    </>
  );
};

/**
 * WIZARD STEP 3
 *
 * Correção dos dados de tipo de educador com problemas de digitação
 * Actions disponíveis: ok, problem
 */
const WizardStep3 = ({ nextStep }) => {
  const { processedData, databaseData } = useContext(PBUContext);

  const processedHtml = () => {
    const obj = processedData.teacherTypes();
    return Object.keys(obj).map((key) => {
      const elem = obj[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={elem.key} bgcolor={color}>
          <td>
            <p>{status}</p>
          </td>
          {elem.action === "problem" ? (
            <EditableSelect
              inputKey={elem.key}
              text={elem.name}
              dataSub="teacherTypes"
              options={Object.keys(databaseData.teacherTypes).map((key) => {
                return {
                  label: databaseData.teacherTypes[key],
                  value: databaseData.teacherTypes[key],
                };
              })}
            />
          ) : (
            <>
              <td>{elem.name}</td>
              <td>-</td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Tipos</h3>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Tipo</th>
            <th>Trocar</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <SubmitStep dataSub="teacherTypes" nextStep={nextStep} />
    </>
  );
};

/**
 * WIZARD STEP 4
 *
 * Correção dos nomes dos professores
 * Actions disponíveis: ok, problem, duplicated, update, insert, delete
 */
const WizardStep4 = ({ nextStep }) => {
  const { processedData, fuzzySSTeachersReady, fuzzyDBTeachersReady } =
    useContext(PBUContext);
  const [currentPage, setCurrentPage] = useState(1);

  if (!fuzzySSTeachersReady || !fuzzyDBTeachersReady)
    return <h3>Carregando fuzzy</h3>;

  const entries = processedData.teachers().sort((a, b) => {
    return a.name.localeCompare(b.name, "pt-br", { sensitivity: "base" });
  });
  const entriesPerPage = 100;
  const totalPages = Math.ceil(entries.length / entriesPerPage);
  const indexOfLast = currentPage * entriesPerPage;
  const indexOfFirst = indexOfLast - entriesPerPage;
  const currentEntries = entries.slice(indexOfFirst, indexOfLast);

  const processedHtml = () => {
    return Object.keys(currentEntries).map((key) => {
      const elem = currentEntries[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={`${elem.key}.${elem.name}`} bgcolor={color}>
          <td>
            <p>{status}</p>
            <RemoveBtn
              action={elem.action}
              inputKey={elem.key}
              dataSub="teachers"
            />
            <Fuzzy
              action={elem.action}
              inputKey={elem.key}
              dataSub="teachers"
            />
          </td>
          <td>
            {elem.action !== "delete" ? (
              <EditableInput
                text={elem.name}
                inputKey={elem.key}
                dataSub="teachers"
              />
            ) : (
              elem.name
            )}
          </td>
          <td>{elem.email ?? "-"}</td>
          <td>{elem.school}</td>
          <td>{elem.type}</td>
          <td>{elem.levels[0]}</td>
          <td>{elem.levels[1]}</td>
          <td>{elem.levels[2]}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Educadores</h3>
      <strong>A. Defina o modo de edição dos usuários</strong>
      <GoalChange />
      <br />
      <strong>B. Valide os dados</strong>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Nome do educador</th>
            <th>Email</th>
            <th>Escola</th>
            <th>Tipo</th>
            <th>Nível 1</th>
            <th>Nível 2</th>
            <th>Nível 3</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <br />
      <ClickPagination
        currentPage={currentPage ? currentPage : 1}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      <SubmitStep dataSub="teachers" nextStep={nextStep} />
    </>
  );
};

/**
 * WIZARD STEP 5
 *
 * Correção dos nomes das séries
 * Actions disponíveis: ok, problem
 */
const WizardStep5 = ({ nextStep }) => {
  const { processedData, databaseData } = useContext(PBUContext);

  const processedHtml = () => {
    const obj = processedData.series();
    return Object.keys(obj).map((key) => {
      const elem = obj[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={elem.key} bgcolor={color}>
          <td>
            <p>{status}</p>
          </td>
          <td>{elem.level}</td>
          {elem.action === "problem" ? (
            <EditableSelect
              inputKey={elem.key}
              text={elem.label}
              dataSub="series"
              options={Object.keys(databaseData.series)
                .filter(
                  (key) =>
                    databaseData.series[key].level === parseInt(elem.levelId),
                )
                .map((key) => {
                  return {
                    label: databaseData.series[key].label,
                    value: databaseData.series[key].label,
                  };
                })}
            />
          ) : (
            <>
              <td>{elem.label}</td>
              <td>-</td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Séries</h3>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Nível</th>
            <th>Série</th>
            <th>Trocar</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <SubmitStep dataSub="series" nextStep={nextStep} />
    </>
  );
};

/**
 * WIZARD STEP 6
 *
 * Correção dos nomes das turmas
 * Actions disponíveis: ok, problem
 */
const WizardStep6 = ({ nextStep }) => {
  const { processedData } = useContext(PBUContext);

  const processedHtml = () => {
    const obj = processedData.classes();
    return Object.keys(obj).map((key) => {
      const elem = obj[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={elem.key} bgcolor={color}>
          <td>
            <p>{status}</p>
          </td>
          <td>{elem.school}</td>
          <td>{elem.level}</td>
          <td>{elem.serie}</td>
          <td>
            {elem.action === "insert" ? (
              <EditableInput
                text={elem.label}
                inputKey={elem.key}
                dataSub="classes"
              />
            ) : (
              elem.label
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Turmas</h3>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Escola</th>
            <th>Nível</th>
            <th>Série</th>
            <th>Turma</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <SubmitStep dataSub="classes" nextStep={nextStep} />
    </>
  );
};

/**
 * WIZARD STEP 7
 *
 * Correção dos nomes dos alunos
 * Actions disponíveis: ok, problem, duplicated, update, insert, delete
 */
const WizardStep7 = ({ nextStep }) => {
  const { processedData, fuzzySSStudentsReady, fuzzyDBStudentsReady } =
    useContext(PBUContext);
  const [currentPage, setCurrentPage] = useState(1);

  if (!fuzzySSStudentsReady || !fuzzyDBStudentsReady)
    return <h3>Carregando fuzzy</h3>;

  const sortPrecedence = {
    duplicated: 0,
    problem: 1,
    update: 2,
    insert: 3,
    ok: 4,
    delete: 5,
  };
  const entries = processedData.students().sort((a, b) => {
    if (a.action !== b.action) {
      if (sortPrecedence[a.action] < sortPrecedence[b.action]) {
        return -1;
      }
      return 1;
    }
    return a.name.localeCompare(b.name, "pt-br", { sensitivity: "base" });
  });
  const entriesPerPage = 100;
  const totalPages = Math.ceil(entries.length / entriesPerPage);
  const indexOfLast = currentPage * entriesPerPage;
  const indexOfFirst = indexOfLast - entriesPerPage;
  const currentEntries = entries.slice(indexOfFirst, indexOfLast);

  const processedHtml = () => {
    return Object.keys(currentEntries).map((key) => {
      const elem = currentEntries[key];
      const { status, color } = processedData.getStatusColor(elem);

      return (
        <tr key={`${elem.key}.${elem.name}`} bgcolor={color}>
          <td>
            <p>{status}</p>
            <RemoveBtn
              action={elem.action}
              inputKey={elem.key}
              dataSub="students"
            />
            <Fuzzy
              action={elem.action}
              inputKey={elem.key}
              dataSub="students"
            />
          </td>
          <td>
            {elem.action !== "delete" ? (
              <EditableInput
                text={elem.name}
                inputKey={elem.key}
                dataSub="students"
              />
            ) : (
              elem.name
            )}
          </td>
          <td>{elem.email ?? "-"}</td>
          <td>{elem.school}</td>
          <td>{elem.level}</td>
          <td>{elem.serie}</td>
          <td>{elem.class}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Alunos</h3>
      <strong>A. Defina o modo de edição dos usuários</strong>
      <GoalChange />
      <br />
      <strong>B. Valide os dados</strong>
      <table border="1">
        <thead>
          <tr>
            <th>STATUS</th>
            <th>Nome do aluno</th>
            <th>Email</th>
            <th>Escola</th>
            <th>Nível</th>
            <th>Série</th>
            <th>Turma</th>
          </tr>
        </thead>
        <tbody>{processedHtml()}</tbody>
      </table>
      <br />
      <ClickPagination
        currentPage={currentPage ? currentPage : 1}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      <SubmitStep dataSub="students" nextStep={nextStep} />
    </>
  );
};

/**
 * WizardStepFinish
 *
 */
const WizardStepFinish = () => {
  return <h1>Os registros foram enfileirados e serão inseridos em breve</h1>;
};

/**
 * EditableInput
 * inputKey: id no spreadsheetData
 * text: valor do input
 * dataSub: subcategoria do spreadsheetData correspondente ao id
 *
 * Cria um campo editável que atualiza o spreadsheetData
 * quando editado
 */
const EditableInput = ({ text, inputKey, dataSub }) => {
  const [value, setValue] = useState(text);
  const id = inputKey.split(".")[1] || inputKey;
  const { updateSSData } = useContext(PBUContext);

  const handleBlur = () => updateSSData[dataSub].run(id, value);
  const handleChange = (e) => setValue(e.target.value);

  return <input value={value} onBlur={handleBlur} onChange={handleChange} />;
};

/**
 * EditableSelect
 * inputKey: id no spreadsheetData
 * text: valor do input
 * dataSub: subcategoria do spreadsheetData correspondente ao id
 * options: lista de opções disponíveis para a troca do valor original
 *
 * Cria um campo editável do tipo select que atualiza o
 * spreadsheetData quando editado
 */
const EditableSelect = ({ text, inputKey, dataSub, options }) => {
  const [value, setValue] = useState(text);
  const [renderTrigger, setRenderTrigger] = useState(false);
  const id = inputKey.split(".")[1] || inputKey;
  const { updateSSData } = useContext(PBUContext);

  const handleChange = (selectedOption) => {
    const newValue =
      typeof selectedOption === "string"
        ? { value: selectedOption, label: selectedOption }
        : selectedOption;

    if (!newValue || !newValue.value) {
      console.error("Erro: opção selecionada é undefined ou inválida", newValue);
      return;
    }

    setValue(newValue);
    updateSSData[dataSub].run(id, newValue.value);
    setRenderTrigger((prev) => !prev);
  };

  return (
    <>
      <td>{value}</td>
      <td>
        <ClickSelect
          id={id}
          options={options}
          onChange={handleChange}
          value={options.find((o) => o.value === value) || null}
        />
      </td>
    </>
  );
};

/**
 * RemoveBtn
 *
 * Remove a entrada da lista de mudanças. Só pode ser utilizada se
 * a entrada estiver marcada para ser deletada ou se for duplicada
 */
const RemoveBtn = ({ action, inputKey, dataSub }) => {
  const { removeData } = useContext(PBUContext);

  return (
    ["delete", "duplicated"].includes(action) && (
      <span>
        {" "}
        <button onClick={() => removeData[dataSub](inputKey)}>Remover</button>
      </span>
    )
  );
};

/**
 * Fuzzy
 *
 * Gera uma lista de opções para um campo marcado inicialmente para
 * "criação". Essas opções são coletadas através do método de aproximação
 * (fuzzy search)
 */
const Fuzzy = ({ action, inputKey, dataSub }) => {
  const { updateSSData, fuzzyData } = useContext(PBUContext);
  const id = inputKey.split(".")[1] || inputKey;

  if (action !== "insert") return "";
  if (!fuzzyData) return "";

  const changeName = (newValue) => updateSSData[dataSub].run(id, newValue);
  const substitute = (set) =>
    set.map((el, index) => (
      <div key={`ss.${id}.${el.name}.${index}`}>
        <span title={el.school}>{`"${el.name}" - `}</span>
        <button onClick={() => changeName(el.name)}>Utilizar</button>
      </div>
    ));

  const dbSet = fuzzyData.db[dataSub];
  const dbSubstitutes = dbSet[id]
    ? [<br key="brdb" />, <p key="txtdb">Similares já existentes:</p>].concat(
      substitute(dbSet[id]),
    )
    : "";
  const ssSet = fuzzyData.ss[dataSub];
  const ssSubstitutes = ssSet[id]
    ? [<br key="brss" />, <p key="txtss">Possíveis duplicados:</p>].concat(
      substitute(ssSet[id]),
    )
    : "";

  return (
    <>
      {dbSubstitutes}
      {ssSubstitutes}
    </>
  );
};

/**
 * GoalChange
 *
 * Mudar o objetivo do BulkEdit
 * - insert: realiza apenas inserções e atualizações
 * - update: realiza inserções, atualizações e deleções de todos os
 * professores e alunos que não estão na lista
 */
const GoalChange = () => {
  const { goal, changeGoal } = useContext(PBUContext);
  const handleChange = (e) => changeGoal(e.value);
  const options = [
    { label: "Fazer apenas inserções", value: "insert" },
    { label: "Inserir e deletar", value: "update" },
  ];
  const defaultValue = options.find((el) => el.value === goal);

  return (
    <div>
      <ClickSelect
        id="goalChange"
        options={options}
        value={defaultValue}
        onChange={handleChange}
      />
      <br />
    </div>
  );
};

/**
 * SubmitStep
 *
 * Botão para submeter os dados apresentados e ir para o próximo passo.
 * @param string dataSub
 * @param callback nextStep
 */
const SubmitStep = ({ dataSub, nextStep }) => {
  const { updateDatabase, processedData } = useContext(PBUContext);
  const { reloadProjects } = useContext(UMContext);

  const clickSubmit = () => {
    if (processedData.hasProblems[dataSub]()) {
      alert("Corrija os problemas");
    } else {
      updateDatabase[dataSub](nextStep, reloadProjects);
    }
  };

  return (
    <>
      <br />
      <br />
      <button onClick={clickSubmit}>Dados confirmados, avançar</button>
    </>
  );
};

/**
 * ProjectBulkUpdateWizard
 *
 */
const ProjectBulkUpdateWizard = () => {
  const { dataIsReady, wizardNextStep } = useContext(PBUContext);

  const [step, setStep] = useState(wizardNextStep(0));
  const nextStep = () => setStep(wizardNextStep(step));

  if (!dataIsReady) return <h1>Carregando...</h1>;
  if (step === 1) return <WizardStep1 nextStep={nextStep} />;
  if (step === 2) return <WizardStep2 nextStep={nextStep} />;
  if (step === 3) return <WizardStep3 nextStep={nextStep} />;
  if (step === 4) return <WizardStep4 nextStep={nextStep} />;
  if (step === 5) return <WizardStep5 nextStep={nextStep} />;
  if (step === 6) return <WizardStep6 nextStep={nextStep} />;
  if (step === 7) return <WizardStep7 nextStep={nextStep} />;
  return <WizardStepFinish />;
};

export default ProjectBulkUpdateWizard;
